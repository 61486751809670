
















import { Component, Vue } from 'vue-property-decorator';
import SiteFooter from '@/components/SiteFooter.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import WhoWeAre from '@/components/WhoWeAre.vue';

@Component({
  components:{
    SiteHeader,
    SiteFooter, 
    WhoWeAre
  }
})
export default class App extends Vue {
}
