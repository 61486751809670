








































































import { bus } from '@/main';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SiteHeader extends Vue {

  title: string | null = null; 
  subtitle: string | null = null; 
  buttons: any[] | null = null;
  bgVideo: string | null = null;

  mounted() { 
    bus.$on('setHeader', (data: { 
      title?: string; 
      subtitle?: string; 
      buttons?: any[];
      bgVideo?: string;
    }) => { 
      this.title = data.title || null;
      this.subtitle = data.subtitle || null;
      this.buttons = data.buttons || null;
      this.bgVideo = data.bgVideo || null;

      if (this.buttons && this.buttons.length){
        this.buttons.forEach(async b => { 
          console.log('button',b.link);
          b.data = await this.$store.dispatch('getItem', b.link.cached_url);
        });
      }
    });
  }

  goto(route: string){ 
    this.mobileNavOpen = false;
    this.$router.push(route);
  }
  
  get navItems(){ 
    return this.$store.state.navItems;
  }
  mobileNavOpen = false;

  toggleMobileMenu(){ 
    this.mobileNavOpen = !this.mobileNavOpen;
  }
}
